<template>
  <v-content>
    <v-progress-linear v-if="loading" :indeterminate="true" class="ma-0 accent"></v-progress-linear>
    <v-container v-if="this.$mq === 'xl' || this.$mq === 'lg' || this.$mq === 'md'" fluid class="pa-0" fill-height>
      <v-layout wrap align-top class="pa-0" justify-left nested>
        <v-flex
          xs12
          lg3
          md4
          :style="`height:${windowHeight}px;overflow:scroll;border-right:1px rgba(0,0,0,.12) solid;`"
        >
          <div v-if="showUpdateMessage">
            <v-alert :value="true" type="warning" @click="getNumbers">
              Hay un mensaje nuevo <br />
              <strong>ACTUALICE AQUÍ</strong>
            </v-alert>
          </div>
          <v-list two-line class="pa-0">
            <template v-for="(phone, index) in phones">
              <v-list-tile
                :key="index"
                avatar
                :class="listClass(phone)"
                @click="goChatMessenger(phone, phone.key, index)"
              >
                <v-list-tile-avatar>
                  <v-progress-circular
                    v-if="wait && number === phone.phone"
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                  <AvatarLetter v-else :text-class="'headline'" :text="phone.name" color="red" />
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title v-html="ucfwords(phone.name)"></v-list-tile-title>
                  <v-list-tile-sub-title>{{ ucfwords(phone.last_body) }}</v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text> {{ $moment.unix(phone.time).fromNow() }} </v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-divider :key="`divider${index}`"></v-divider>
            </template>
            <v-list-tile v-if="phones.length > 0">
              <v-btn color="accent" block depressed @click="moreNumber"> Ver más </v-btn>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex :class="getGridContent">
          <template v-if="this.number">
            <ChatWhatsapp
              :get-numbers="getNumbers"
              :show-more="true"
              :user-actives="this.userActives"
              :number-active="this.number"
            />
          </template>
          <template v-else>
            <v-layout align-center justify-center row fill-height>
              <h1 class="display-1 grey--text text--lighten-1 text-xs-center">
                <v-icon color="grey" class="mb-1" :size="50"> subdirectory_arrow_left </v-icon> <br />
                Seleccione un número
              </h1>
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container v-else fluid class="pa-0" fill-height>
      <v-layout align-start justify-start row fill-height>
        <v-flex
          v-if="this.number === '' || this.number === null || this.number === undefined"
          xs12
          lg3
          xl2
          :style="`height:${windowHeight}px;overflow:scroll`"
          fill-height
        >
          <v-list two-line class="pa-0" style="border-right:1px rgba(0,0,0,.12) solid;">
            <template v-for="(phone, index) in phones">
              <v-list-tile
                :key="index"
                avatar
                :class="listClass(phone)"
                @click="goChatMessenger(phone, phone.key, index)"
              >
                <v-list-tile-avatar>
                  <v-progress-circular
                    v-if="wait && number === phone.phone"
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                  <AvatarLetter v-else :text-class="'headline'" :text="phone.name" color="red" />
                </v-list-tile-avatar>

                <v-list-tile-content>
                  <v-list-tile-title>{{ ucfwords(phone.name) }}</v-list-tile-title>
                  <v-list-tile-sub-title>{{ ucfwords(phone.last_body) }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider :key="`divider${index}`"></v-divider>
            </template>
            <v-list-tile v-if="phones.length > 0">
              <v-btn color="accent" block depressed @click="moreNumber"> Ver más </v-btn>
            </v-list-tile>
          </v-list>
        </v-flex>
        <v-flex v-else :class="getGridContent">
          <template v-if="this.number">
            <ChatWhatsapp
              :get-numbers="getNumbers"
              :show-more="true"
              :user-actives="this.userActives"
              :number-active="this.number"
            />
          </template>
          <template v-else>
            <v-layout align-center justify-center row fill-height>
              <h1 class="display-1 grey--text text--lighten-1 text-xs-center">
                <v-icon color="grey" class="mb-1" :size="50"> subdirectory_arrow_left </v-icon> <br />
                Seleccione un número
              </h1>
            </v-layout>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { firestore } from '../../fireStore'
import AvatarLetter from '../general/avatarLetter.vue'
import ChatWhatsapp from './chat.vue'

export default {
  name: 'WhatsApp',
  components: { AvatarLetter, ChatWhatsapp },
  props: ['number'],
  data() {
    return {
      userActives: [],
      getMessageStatus: null,
      userActivesEvent: null,
      dark: false,
      loading: false,
      windowHeight: window.innerHeight - 63,
      limit: 0,
      client: [],
      showMore: true,
      clientActive: false,
      wait: false,
      more: false,
      phoneActive: null,
      paginate: 8,
      phones: [],
      messages: [],
      title: 'Chats de WhatsApp',
      showUpdateMessage: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    notification() {
      const notifications = this.$store.getters.getNotifications
      return notifications
    },
    getGridContent() {
      if (this.clientActive && this._.size(this.client) > 0) {
        return 'xs12 lg6 md5 border-right'
      }
      return 'xs12 lg9 md8'
    }
  },
  watch: {
    clientActive() {
      this.windowHeight = window.innerHeight - 400
    },
    notification() {
      this.showUpdateMessage = true
      // this.getNumbers();
    },
    number() {
      // this.getMessages(e);
    },
    user() {
      this.setUserActive()
    }
  },
  beforeDestroy() {
    if (this.userActivesEvent !== null) {
      this.userActivesEvent()
    }
  },
  mounted() {
    this.getNumbers()
    this.getUsersActives()
    this.setUserActive()
    this.$store.dispatch('updateTitle', this.title)
  },
  methods: {
    moreNumber() {
      this.paginate = this.paginate + 25
      this.getNumbers()
    },
    listClass(phone) {
      if (phone.read === false) {
        return 'no-read'
      }
      if (this.number === phone.phone) {
        return 'active'
      }
      return ''
    },
    getNumbers() {
      const self = this
      firestore
        .collection('users')
        .orderBy('time', 'desc')
        .limit(this.paginate)
        .get()
        .then(querySnapshot => {
          const phones = []
          querySnapshot.forEach(doc => {
            const notification = doc.data()
            notification.see = 1
            notification.key = doc.id
            firestore
              .collection('users')
              .doc(doc.id)
              .collection('messages')
              .where('read', '==', 0)
              .limit(1)
              .get()
              .then(querySnapshotTwo => {
                if (querySnapshotTwo.docs.length > 0) {
                  notification.see = 0
                }
              })
            phones.push(notification)
          })
          self.phones = phones
          self.showUpdateMessage = false
        })
        .catch(error => {
          console.log('Error getting documents: ', error)
        })
    },
    ucfwords(str) {
      return str
        .toLowerCase()
        .replace(/^[\u00C0-\u1FFF\u2C00-\uD7FF\w]|\s[\u00C0-\u1FFF\u2C00-\uD7FF\w]/g, letter => letter.toUpperCase())
    },
    goChatMessenger(item, key, index) {
      const self = this
      this.$router.push({ name: 'whatsappChat', params: { number: item.phone } })
      if (!item.see) {
        firestore
          .collection('users')
          .doc(key)
          .collection('messages')
          .where('read', '==', 0)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              firestore
                .collection('users')
                .doc(item.phone)
                .collection('messages')
                .doc(doc.id)
                .update({
                  read: 1,
                  read_user: self.user.name
                })
            })
          })
          .catch(error => {
            console.log('Error getting documents: ', error)
          })
        firestore
          .collection('users')
          .doc(key)
          .update({
            read: true
          })
        const notifications = this.$store.getters.getNotifications
        this.phones[index].read = false
        notifications[index].read = true
        this.wait = false
        self.$store.dispatch('setNotifications', notifications)
      }
    },
    activeMore() {
      this.clientActive = !this.clientActive
    },
    getClient(number) {
      this.clientActive = false
      this.client = []
      this.$http({
        method: 'post',
        url: GET_API,
        params: {
          p: 'getClientSummary',
          phone: number,
          id: 0
        }
      }).then(res => {
        this.client = res.data.json
      })
    },
    getUsersActives() {
      const self = this
      this.userActivesEvent = firestore.collection('usersActives').onSnapshot(querySnapshot => {
        const userActives = []
        querySnapshot.forEach(doc => {
          userActives.push(doc.data())
        })
        self.userActives = userActives
      })
    },
    setUserActive() {
      const self = this
      if (self.user) {
        firestore
          .collection('usersActives')
          .doc(`user-${this.user.id}`)
          .set({
            name: self.user.name,
            write: false,
            phone: self.number ? self.number : 0,
            id: self.user.id
          })
          .then(() => {
            // console.log("Document successfully written!");
          })
          .catch(error => {
            console.error('Error writing document: ', error)
          })
      }
    }
  },
  metaInfo() {
    return {
      title: this.title
    }
  }
}
</script>

<style>
.v-list .no-read {
  background: #ff52521f;
}
.v-list .active a {
  background: rgba(0, 0, 0, 0.04);
}
.border-right {
  border-right: 1px rgba(0, 0, 0, 0.12) solid;
}
</style>
