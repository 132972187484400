import { render, staticRenderFns } from "./index.vue?vue&type=template&id=66fb7160&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileActionText } from 'vuetify/lib'
import { VListTileAvatar } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileSubTitle } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
installComponents(component, {VAlert,VBtn,VContainer,VContent,VDivider,VFlex,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileActionText,VListTileAvatar,VListTileContent,VListTileSubTitle,VListTileTitle,VProgressCircular,VProgressLinear})
